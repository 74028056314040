<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->

    <b-overlay :show="formShow" rounded="sm" no-fade>
      <b-row>
        <b-col cols="12" xl="12" md="12">
          <b-card no-body>
            <b-card-body>
              <b-row class="my-2">
                <profile-cropper v-if="selectedImage" :image="selectedImage" @cropped="imageCropped" />
                <!-- image upload -->
                <b-col cols="2">
                  <b-img-lazy rounded thumbnail fluid v-bind="mainProps" class="d-inline-block mr-1" style="width: 100%" ref="refPreviewEl" :src="imageUrl" />

                  <b-button v-show="$Can('employees_upload_btn')" v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" @click="$refs.refInputEl.click()" block variant="primary">
                    Upload
                  </b-button>
                </b-col>

                <input ref="refInputEl" type="file" class="d-none" accept="image/*" @input="imageSelected" />

                <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
                  <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
                </b-col>

                <b-col cols="10">
                  <b-row class="justify-content-between">
                    <h1 class="ml-1">{{ employeeData.name + ' ' + employeeData.surname }}</h1>
                    <div>
                      <b-button v-show="$Can('employees_activate_btn')" class="mr-1" v-if="employeeData.status != 'active'" @click="activateEmployee" variant="success">
                        Activate
                      </b-button>

                      <b-button v-show="$Can('employees_remove_btn')" class="mr-1" v-if="employeeData.status != 'deleted'" @click="removeEmployee" variant="danger">
                        Remove
                      </b-button>

                      <b-button v-show="$Can('employees_terminate_btn')" class="mr-1" v-if="employeeData.status != 'passive'" @click="terminateEmployee" variant="warning">
                        Deactivate
                      </b-button>

                      <b-button v-show="$Can('employees_edit_btn')" class="mr-1" @click="editEmployee" variant="primary">
                        Edit
                      </b-button>
                    </div>
                  </b-row>

                  <table class="mt-2 table  table-bordered table-hover table-sm">
                    <tbody>
                      <tr>
                        <th class="w-25 bg-primary text-white" scope="row">
                          Role
                        </th>
                        <td class="w-75" v-if="employeeData.role == 'Foreman'">🤵🏻 {{ employeeData.role }}</td>

                        <td class="w-75" v-else>🧍🏻 {{ employeeData.role }}</td>
                      </tr>

                      <tr>
                        <th class="w-25 bg-primary text-white" scope="row">
                          Status
                        </th>
                        <td class="w-75">
                          <b-badge v-if="employeeData.status == 'active'" pill :variant="`light-success`" class="text-capitalize">
                            Active
                          </b-badge>

                          <b-badge v-if="employeeData.status == 'passive'" pill :variant="`light-warning`" class="text-capitalize">
                            Inactive
                          </b-badge>

                          <b-badge v-if="employeeData.status == 'deleted'" pill :variant="`light-danger`" class="text-capitalize">
                            Removed
                          </b-badge>
                        </td>
                      </tr>

                      <tr>
                        <th class="w-25 bg-primary text-white" scope="row">
                          Availability
                        </th>
                        <td class="w-75">
                          <b-badge v-if="employeeData.availability == 'A'" pill :variant="`light-success`" class="text-capitalize">
                            Available
                          </b-badge>

                          <b-badge v-if="employeeData.availability == 'S'" pill :variant="`light-warning`" class="text-capitalize">
                            Sick
                          </b-badge>

                          <b-badge v-if="employeeData.availability == 'H'" pill :variant="`info`" class="text-capitalize">
                            Holiday
                          </b-badge>

                          <b-badge v-if="employeeData.availability == 'E'" pill :variant="`primary`" class="text-capitalize">
                            Working
                          </b-badge>

                          <b-badge v-if="employeeData.availability == 'W'" pill :variant="`danger`" class="text-capitalize">
                            AWOL
                          </b-badge>
                        </td>
                      </tr>

                      <tr>
                        <th class="w-25 bg-primary text-white" scope="row">
                          Driver
                        </th>
                        <td class="w-75">
                          <span v-if="employeeData.is_driver == true"> ☑️ Yes </span>
                          <span v-else> ✖️ No </span>
                        </td>
                      </tr>

                      <tr>
                        <th class="w-25 bg-primary text-white" scope="row">
                          Email
                        </th>
                        <td class="w-75">
                          {{ employeeData.email }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <b-card>
            <b-tabs vertical nav-wrapper-class="nav-vertical" pills class="tab-class">
              <b-tab active title="General Information" v-if="$Can('employees_information_tab')">
                <b-card-body class="mt-0 pt-0">
                  <!-- <h3>General Information</h3> -->
                  <b-row>
                    <table class=" table  table-bordered table-hover  ">
                      <tbody class="text-left">
                        <tr>
                          <td class="item-price bg-secondary text-white w-25">First name</td>
                          <td class="w-75">{{ employeeData.name }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Last name</td>
                          <td>{{ employeeData.surname }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Phone</td>
                          <td>{{ employeeData.phone }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Email</td>
                          <td>{{ employeeData.email }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Role</td>
                          <td>{{ employeeData.role }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Date Of Birth</td>
                          <td>{{ dateFormat(employeeData.dob) }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Address</td>
                          <td>{{ employeeData.address1 }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Post Code</td>
                          <td>{{ employeeData.post_code }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Phone</td>
                          <td>{{ employeeData.phone }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">City</td>
                          <td>{{ employeeData.city }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Address 2</td>
                          <td>{{ employeeData.address2 }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Passport No</td>
                          <td>{{ employeeData.passport_no }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Passport Expiry</td>
                          <td>{{ dateFormat(employeeData.passport_expiry) }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Driving License</td>
                          <td>{{ employeeData.driving_license }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">Forklift License</td>
                          <td>{{ employeeData.forklift_license }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">CSCS</td>
                          <td>{{ employeeData.cscs }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">NI Number</td>
                          <td>{{ employeeData.ni_no }}</td>
                        </tr>

                        <tr>
                          <td class="item-price bg-secondary text-white">IPAF Expiry</td>
                          <td>{{ dateFormat(employeeData.ipaf_expiry) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </b-row>
                </b-card-body>
              </b-tab>

              <b-tab title="Rates" v-if="$Can('employees_rates_tab')">
                <b-card-body class="mt-0 pt-0">
                  <b-row>
                    <b-col cols="12">
                      <table class=" table  table-bordered table-hover  ">
                        <tbody class="text-left">
                          <tr>
                            <td class="item-price bg-secondary text-white w-25">Day Rate UK</td>
                            <td class="w-75">{{ $func.formatPrice(employeeData.day_rate) }}</td>
                          </tr>

                          <tr>
                            <td class="item-price bg-secondary text-white">Weekend Rate UK</td>
                            <td>{{ $func.formatPrice(employeeData.weekend_rate) }}</td>
                          </tr>

                          <tr>
                            <td class="item-price bg-secondary text-white w-25">Day Rate EU</td>
                            <td class="w-75">{{ $func.formatPrice(employeeData.day_rate_eu) }}</td>
                          </tr>

                          <tr>
                            <td class="item-price bg-secondary text-white">Weekend Rate EU</td>
                            <td>{{ $func.formatPrice(employeeData.weekend_rate_eu) }}</td>
                          </tr>

                          <tr>
                            <td class="item-price bg-secondary text-white">Day Rate Global</td>
                            <td>{{ $func.formatPrice(employeeData.day_rate_glo) }}</td>
                          </tr>

                          <tr>
                            <td class="item-price bg-secondary text-white">Weekend Rate Global</td>
                            <td>{{ $func.formatPrice(employeeData.weekend_rate_glo) }}</td>
                          </tr>

                          <tr>
                            <td class="item-price bg-secondary text-white">Overtime Rate</td>
                            <td>{{ $func.formatPrice(employeeData.overtime) }}</td>
                          </tr>

                          <tr>
                            <td class="item-price bg-secondary text-white">Lodging Allowance</td>
                            <td>{{ $func.formatPrice(employeeData.lodging) }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-tab>

              <b-tab title="Teams" v-if="$Can('employees_teams_tab')">
                <b-table
                  selectable
                  select-mode="single"
                  @row-selected="onTeamRowSelected"
                  striped
                  hover
                  ref="refUserListTable"
                  class="position-relative"
                  :style="totalRows < 3 ? 'height: 500px' : ''"
                  :items="fetchTeams"
                  responsive
                  :small="true"
                  head-variant="dark"
                  :fields="teamTableColumns"
                  show-empty
                  empty-text="No matching records found"
                >
                  <!-- Column: Id -->
                  <template #cell(id)="data">
                    <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
                    <strong class="text-primary">#{{ data.value }}</strong>
                  </template>

                  <!-- Column: Title -->
                  <template #cell(title)="data">
                    {{ data.value }}
                  </template>

                  <!-- Column: Foreman -->
                  <template #cell(foreman)="data">
                    {{ data.value }}
                  </template>

                  <!-- Column: Surname -->
                  <template #cell(surname)="data">
                    {{ data.value }}
                  </template>

                  <!-- Column: Role -->
                  <template #cell(role)="data">
                    <b-badge pill :variant="`light-primary`" class="text-capitalize">
                      {{ data.value }}
                    </b-badge>
                  </template>

                  <template #cell(day_rate)="data">
                    {{ $func.formatPrice(data.value) }}
                  </template>

                  <!-- Column: Status -->
                  <template #cell(status)="data">
                    <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                      Active
                    </b-badge>

                    <b-badge v-if="data.value == 'P'" pill :variant="`light-danger`" class="text-capitalize">
                      Passive
                    </b-badge>

                    <b-badge v-if="data.value == 'D'" pill :variant="`danger`" class="text-capitalize">
                      Deleted
                    </b-badge>
                  </template>
                </b-table>
              </b-tab>

              <b-tab title="Time Off" v-if="$Can('employees_time_off_tab')">
                <validation-observer ref="timeOffRuless">
                  <b-form>
                    <b-row v-if="employeeData.id" class="mt-2  border-bottom pb-1">
                      <b-col sm="3" md="3" xl="3">
                        <b-form-group label="Availability" label-for="availability">
                          <validation-provider name="Availability" #default="{ errors }" rules="required">
                            <v-select v-model="availability" :options="availabilityOptions" :reduce="(val) => val.value" :clearable="false" input-id="availability" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col xl="3" md="3" sm="3">
                        <b-form-group label="Date Range" label-for="Date Range">
                          <validation-provider name="Date Range" #default="{ errors }" rules="required">
                            <flat-pickr placeholder="Date Range" v-model="rangeDate" class="form-control" :config="{ mode: 'multiple', dateFormat: 'd-m-Y' }" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col xl="6" md="6" sm="6" class="btn-small mt-2">
                        <b-button @click="clearDate()" variant="success" class="mr-2">Clear</b-button>
                        <b-button v-show="$Can('employees_availabilty_function')" type="submit" @click.prevent="availabilityAdd" variant="primary">
                          Add
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form>
                </validation-observer>
                <b-row v-if="employeeData.id" class="mt-2">
                  <b-col cols="12">
                    <b-table striped hover ref="refUserListTable" class="position-relative" :items="fetchAvailability" filter-debounce="250" responsive :small="true" head-variant="dark" :fields="tableColumns" show-empty empty-text="No matching records found">
                      <template #cell(date)="data"> {{ data.value }} </template>

                      <template #cell(availability)="data">
                        <b-badge v-if="data.value == 'A'" pill :variant="`light-success`" class="text-capitalize">
                          Available
                        </b-badge>

                        <b-badge v-if="data.value == 'S'" pill :variant="`light-warning`" class="text-capitalize">
                          Sick
                        </b-badge>

                        <b-badge v-if="data.value == 'H'" pill :variant="`info`" class="text-capitalize">
                          Holiday
                        </b-badge>

                        <b-badge v-if="data.value == 'W'" pill :variant="`danger`" class="text-capitalize">
                          AWOL
                        </b-badge>

                        <b-badge v-if="data.value == 'E'" pill :variant="`primary`" class="text-capitalize">
                          WORKING
                        </b-badge>
                      </template>

                      <template #cell(actions)="data">
                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                          <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="14" class="align-middle text-body" />
                          </template>

                          <b-dropdown-item @click="deleteTimeOff('R', data)">
                            <feather-icon icon="XIcon" />
                            <span class="align-middle ml-50">Delete</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </template>
                    </b-table>
                  </b-col>
                </b-row>
              </b-tab>

              <b-tab title="Files" v-if="$Can('employees_time_off_tab')">
                <b-card-body class="mt-0 pt-0">
                  <b-row>
                    <b-button v-show="$Can('employees_files_add_btn')" class="btn-icon ml-auto  mt-1 mb-1" @click="addNewFile()" variant="primary">
                      Add New File
                    </b-button>

                    <table class=" table  table-bordered table-hover table-sm ">
                      <b-col cols="12" v-if="employeeData.id != null">
                        <!-- user suggestion  -->
                        <div v-for="(data, index) in files" :key="index" class="ml-5 mr-5 mt-2 mb-2">
                          <div v-if="data.status == 'A'" class="d-flex justify-content-between align-items-center">
                            <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                            <div class="user-page-info pr-1" style="width: 100%">
                              <span @click="fileEdit(data)" style="cursor:pointer; font-size:1.1em" class="mb-2">{{ data.fileName }} </span>
                            </div>

                            <!-- <b-button  variant="primary" @click="invoiceFile(data)" class="btn-icon ml-auto mr-1" style="padding: 1% 1%;" size="sm">
                          Invoice
                        </b-button> -->

                            <b-button variant="primary" target="_blank" :href="fileURL + data.fileLink" style="cursor:pointer;    margin-right:2px" class="btn-icon ml-auto mr-1" size="sm">
                              <feather-icon icon="DownloadIcon" />
                            </b-button>

                            <b-button variant="danger" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                              <feather-icon icon="XIcon" />
                            </b-button>
                          </div>

                          <div v-else class="d-flex justify-content-between align-items-center">
                            <b-img src="@/assets/images/url.png" @click="linkEdit(data)" class="mr-50 " style="cursor:pointer" width="30px" />
                            <div class="user-page-info pr-1" style="width: 100%">
                              <span @click="linkEdit(data)" style="cursor:pointer; font-size:1.1em">{{ data.fileName }} </span>
                            </div>
                            <b-button variant="primary" target="_blank" :href="'https://' + data.fileLink" style="cursor:alias;  margin-right:2px" class="btn-icon ml-auto mr-1" size="sm" v-b-tooltip.hover title="Go to url adress">
                              <feather-icon icon="ExternalLinkIcon" />
                            </b-button>

                            <b-button variant="danger" @click="deleteFile(data)" class="btn-icon ml-auto mr-1" style="" size="sm">
                              <feather-icon icon="XIcon" />
                            </b-button>
                          </div>
                          <hr />
                        </div>

                        <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
                          No Files
                        </div>
                      </b-col>
                    </table>
                  </b-row>
                </b-card-body>
              </b-tab>

              <b-tab title="Report" v-if="$Can('employees_report_tab') && employeeData.id != null">
                <b-card-text>
                  <employee-report :employee-id="employeeId"></employee-report>
                </b-card-text>
              </b-tab>

              <b-tab title="Timeline" v-if="$Can('employees_timeline_tab')">
                <b-card v-if="employeeData.timeline ? employeeData.timeline.length : 0 > 0" style="overflow-y:scroll; height:500px">
                  <app-timeline class="mt-2">
                    <app-timeline-item v-for="item in employeeData.timeline" :key="item.id" :variant="$func.varyant(item.status)" :icon="$func.varicon(item.status)">
                      <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                        <h6 v-html="item.title"></h6>
                        <small class="text-muted">{{ $func.dateFormatTimeline(item.created_at) }}</small>
                      </div>
                      <small>{{ 'By ' + item.userNameSurname }} - {{ item.ipAddress }}</small>

                    </app-timeline-item>
                  </app-timeline>
                </b-card>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>

        <b-col v-if="false" cols="12" xl="2" md="3">
          <b-card v-if="users.length > 0">
            <p class="text-center mb-1"><strong>Employee List </strong></p>
            <hr />

            <div v-for="user in users" :key="user.id">
              <b-badge variant="dark" class="p-1 mb-1 d-block" style="cursor:pointer" block @click="viewEmployee(user.id)">
                <span style="font-size:1.2em;"> {{ user.full_name }} </span>
              </b-badge>

              <hr />
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
          <b-form-group>
            <b-form-checkbox class="custom-control-primary" name="check-button" switch inline v-model="selectFileType">
              <span class="switch-icon-left">
                <feather-icon icon="FileIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="LinkIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group :state="fileState" invalid-feedback="File is required" v-if="selectFileType">
            <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="animate__animated animate__fadeIn" />
          </b-form-group>
          <b-form-group :state="linkState" invalid-feedback="Link is required" v-else>
            <b-form-input :state="linkState" id="basicInput" v-model="fileLink" required placeholder="Enter Link" class="animate__animated animate__fadeIn" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import store from '@/store';
import router from '@/router';
import { ref, onUnmounted } from '@vue/composition-api';
('bootstrap-vue');
import { BModal, BAvatar, BCard, BCardBody, BRow, BCol, BImg, BLink, BButton, BAlert, BCardHeader, BCardTitle, VBToggle, BTabs, BTab, BCardText, BProgress, BTable } from 'bootstrap-vue';
import moment from 'moment';
import employeeStoreModule from '../employeeStoreModule';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import axiosIns from '@/libs/axios';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, alphaNum, email } from '@validations';
import EmployeeReport from '../employee-report/EmployeeReport.vue';

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

import ProfileCropper from '@core/components/image-cropper/ProfileCropper.vue';
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';

// import { avatarText } from '@core/utils/filter'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BButton,
    BAvatar,
    BModal,
    BCardBody,
    BImg,
    BCardHeader,
    BCardTitle,
    BTabs,
    BCardText,
    BTab,
    ProfileCropper,
    BProgress,
    vSelect,
    flatPickr,
    BTable,
    ValidationProvider,
    ValidationObserver,
    AppTimeline,
    AppTimelineItem,
    EmployeeReport,
  },
  setup() {
    const EMPLOYEE_APP_STORE_MODULE_NAME = 'employee';

    // Register module
    if (!store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.registerModule(EMPLOYEE_APP_STORE_MODULE_NAME, employeeStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EMPLOYEE_APP_STORE_MODULE_NAME)) store.unregisterModule(EMPLOYEE_APP_STORE_MODULE_NAME);
    });
  },

  data: function() {
    var employeeData = {
      timeline: [],

      profile: null,
      id: null,
      name: null,
      surname: null,
      email: null,
      role: null,
      phone: null,
      address1: null,
      address2: null,
      city: null,
      post_code: null,
      dob: null,
      ni_no: null,
      passport_no: null,
      address_time: null,
      ipaf_expiry: null,
      passport_expiry: null,
      driving_license: null,
      day_rate: null,
      weekend_rate: null,
      lodging: null,
      day_rate_glo: null,
      weekend_rate_glo: null,
      forklift_license: null,
      cscs: null,
      is_driver: false,
    };

    var availabilityData = {
      startDate: null,
      endDate: null,
      availability: null,
    };

    const availabilityOptions = [
      { label: 'Holiday', value: 'H' },
      { label: 'Sick', value: 'S' },
      { label: 'AWOL', value: 'W' },
    ];
    return {
      employeeData: employeeData,

      imageUrl: store.state.app.profileURL + 'none.jpg',
      tempImage: null,
      profileURL: store.state.app.profileURL,
      selectedImage: null,
      croppedImage: null,
      userData: {},
      percentCompleted: 0,

      required,
      alphaNum,
      email,

      availabilityData: availabilityData,
      baseURL: store.state.app.baseURL,

      roleOptions: [],
      availabilityOptions,
      employeeId: null,
      formShow: false,
      permissions: [],
      users: [],

      availability: null,

      startDate: null,
      endDate: null,
      rangeDate: null,

      fetchAvailability: [],

      //FILES
      where: router.currentRoute.name,
      files: [],
      selectFileType: true,
      fileLink: null,
      modalId: null,
      modalFileName: null,
      modalFile: null,
      download: false,
      fileURL: store.state.app.fileURL,
      percentCompleted: 0,

      fetchTeams: [],
      totalRows: 0,

      mainProps: {
        blank: true,
        blankColor: '#777',
        width: 75,
        height: 75,
        class: 'm1',
      },

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },

      config: {
        dateFormat: 'd-m-Y',
        mode: 'range',
        locale: { firstDayOfWeek: 1 },
      },

      tableColumns: [
        { key: 'date', sortable: true, class: 'text-center' },
        { key: 'availability', sortable: true, class: 'text-center' },
        { key: 'actions', sortable: true, class: 'text-center' },
      ],

      teamTableColumns: [
        { key: 'id', sortable: true, class: 'text-center' },
        { key: 'title', sortable: true, class: 'text-center' },
        { key: 'foreman', sortable: true, class: 'text-center' },
        { key: 'staff_count', sortable: true, class: 'text-center' },
        { key: 'day_rate', sortable: true, class: 'text-center' },
        { key: 'status', sortable: true, class: 'text-center' },
      ],
    };
  },

  created() {
    this.getEmployeeById();
  },

  methods: {
    editEmployee() {
      router.push({ name: 'employee-edit', params: { id: router.currentRoute.params.id } });
    },

    viewEmployee(id) {
      this.formShow = true;
      store
        .dispatch('employee/fetchEmployee', { id: id })
        .then((response) => {
          this.employeeData = response.data;
          this.fetchAvailability = response.data.availabilityList;
          this.fetchTeams = response.data.fetchTeams;
          this.totalRows = this.fetchTeams.length;
          this.files = response.data.get_files;
          this.users = response.data.userList;
          this.imageUrl = this.profileURL + response.data.profile;

          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
        });
      router.push({ name: 'employee-view', params: { id: id } });
    },

    availabilityAdd() {
      this.$refs.timeOffRuless.validate().then((success) => {
        if (success) {
          var tempData = {
            date: null,
            availability: null,
            userId: null,
          };

          tempData.availability = this.availability;
          tempData.userId = router.currentRoute.params.id;
          let dateSplit = this.rangeDate.split(/[ ,]+/);
          let dateArray = [];
          dateSplit.forEach((element) => {
            dateArray.push(element);
          });

          tempData.date = dateArray;

          tempData.date.forEach((element) => {
            this.fetchAvailability.push({
              date: element,
              availability: tempData.availability,
              userId: tempData.userId,
            });
          });

          store.dispatch('employee/employeeAvailability', tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ Employee Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });

              this.availability = null;
              this.clearDate();
              this.getEmployeeById();
            }
          });
        }
      });
    },

    deleteTimeOff(val, data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteAvailability/${data.item.id}`)
            .then((res) => {
              this.getEmployeeById();
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.startDate = null;
        this.endDate = null;
        this.rangeDate = null;
      }
    },

    activateEmployee() {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Yes, activate the employee!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: 'A',
            userId: router.currentRoute.params.id,
          };
          this.employeeData.status = 'active';

          store.dispatch('employee/userStatus', tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ User Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    terminateEmployee() {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, deactivate the employee!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: 'P',
            userId: router.currentRoute.params.id,
          };
          this.employeeData.status = 'passive';

          store.dispatch('employee/userStatus', tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ User Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    removeEmployee() {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove the employee!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          var tempData = {
            status: 'D',
            userId: router.currentRoute.params.id,
          };

          this.employeeData.status = 'deleted';

          store.dispatch('employee/userStatus', tempData).then((response) => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Successful',
                  text: '✔️ User Update Successful',
                  icon: 'ThumbsUpIcon',
                  variant: 'success',
                },
              });
              this.getEmployeeById();
            }
          });
        }
      });
    },

    getEmployeeById() {
      this.formShow = true;

      if (router.currentRoute.params.id) {
        this.employeeId = parseInt(router.currentRoute.params.id);

        store
          .dispatch('employee/fetchEmployee', { id: router.currentRoute.params.id })
          .then((response) => {
            if (response.status == 200) {
              this.employeeData = response.data;
              this.fetchAvailability = response.data.availabilityList;
              this.fetchTeams = response.data.fetchTeams;
              this.totalRows = this.fetchTeams.length;
              this.files = response.data.get_files;
              this.users = response.data.userList;
              this.imageUrl = this.profileURL + response.data.profile;

              this.formShow = false;
            } else {
              router.push({ name: 'error-404' });
            }
          })
          .catch((error) => {
            router.push({ name: 'error-404' });
          });
      } else {
        this.formShow = false;
      }
    },

    dateFormat(val) {
      if (val != null) {
        moment.locale('en-US');
        return moment(val, 'YYYY-MM-DD').format('DD/MM/YYYY');
      } else {
        return 'N/A';
      }
    },

    onTeamRowSelected(item) {
      router.push({ name: 'team-edit', params: { id: item[0].id } });
    },

    fileEdit(data) {
      this.modalId = data.id;
      this.modalFileName = data.fileName;
      this.$bvModal.show('modal-file-update');
    },

    linkEdit(data) {
      this.modalId = data.id;
      this.modalFileName = data.fileName;
      this.$bvModal.show('modal-file-update');
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteEmployeeFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.files = tempList;
              this.formShow = false;
              this.getEmployeeById();
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    addNewFile() {
      this.$bvModal.show('modal-file');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      if (this.selectFileType) {
        if (this.percentCompleted == 100) {
          var data = {
            user_id: this.employeeData.id,
            fileName: this.modalFileName,
            fileLink: this.modalFile,
            status: 'A',
          };

          // Trigger submit handler
          this.modalOkFile(data);
        }
      } else {
        var data = {
          user_id: this.employeeData.id,
          fileName: this.modalFileName,
          fileLink: this.fileLink,
          status: 'B',
        };
        this.modalOkFile(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    resetModal() {
      this.modalFileName = null;
      this.modalFile = null;
    },

    modalOkFile(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.formShow = true;
      axiosIns
        .post('saveEmployeeFile', data)
        .then((response) => {
          this.files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
            status: response.data.status,
          });

          this.selectFileType = true;
          this.fileLink = null;
          this.modalFileName = null;
          this.modalFile = null;

          this.formShow = false;
          this.getEmployeeById();
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.formShow = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
      };

      axiosIns
        .put('updateEmployeeFile', data)
        .then((response) => {
          this.modalFileName = null;
          this.modalFile = null;

          this.files.forEach((file) => {
            if (file.id == this.modalId) {
              file.fileName = response.data.fileName;
            }
          });

          this.formShow = false;
          this.getEmployeeById();
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadEmployeeFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },

    imageUpload() {},
    imageSelected(event) {
      const file = event.target.files ? event.target.files[0] : null;
      if (file != null) {
        this.getBase64(file).then((data) => {
          this.selectedImage = data;
        });
      }
    },
    imageCropped(croppedImage) {
      if (croppedImage != null) {
        this.formShow = true;
        let formData = new FormData();
        formData.append('id', this.employeeData.id);
        formData.append('file', croppedImage);
        const config = {
          onUploadProgress: function(progressEvent) {
            this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }.bind(this),
        };
        axiosIns
          .post('uploadPreviewPhoto', formData, config)
          .then((response) => {
            this.employeeData.profile = response.data;
            this.imageUrl = this.profileURL + response.data;

            this.tempImage = null;
            this.selectedImage = null;
            this.formShow = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Upload Successful',
                text: '✔️ Image Cropped Successfully',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });
          })
          .catch((error) => {
            console.log(error);
            this.formShow = false;

            
            this.$swal({
                        title: "Error",
                        text: "Image upload not successful. Please try again or contact support.",
                        icon: "error",
                        showCancelButton: false,
                        confirmButtonText: "OK",
                    });

           
          });
      } else {
        this.tempImage = null;
        this.selectedImage = null;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },

  computed: {
    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },
    linkState() {
      let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      let regex = new RegExp(expression);
      let link = this.fileLink;
      if (link != null) {
        if (link.match(regex)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}

.user-title {
  width: 100%;
  font-weight: bold;
}

.user-badge {
  width: 50%;
  align-content: center;
}
</style>

<style scoped>
.tab-class >>> .nav-item {
  position: relative;
  display: flex;
  justify-content: start;
}

.tab-class >>> .nav-vertical {
  padding-left: 0 !important;
}

.tab-class >>> .nav-pills .nav-link.active {
  width: 100%;
}
</style>
